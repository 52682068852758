import React, { useEffect, useContext, useRef, useState, Fragment } from "react";
import { GlobalStateContext } from "../../context/GlobalContextProvider";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Seo from "../../components/seo/seo";
import Layout from "../../components/layout/layout";
import PageBanner from "../../components/common/PageBanner";
import ImagePreview from '../../components/common/ImagePreview';
import Sidebar from '../../components/common/ArticleSidebar'
import SocialMedia from '../../components/common/SocialMedia'
import lsrImg from '../../assets/img/pages/vr-games/VIRSAT-Life-Saving-Rules-Aware.jpg'
import empty from '../../assets/img/icons/empty.png'
import mainImg from '../../assets/img/pages/articles/4/New and innovative way to train contractor workers on fatal HSE risks.jpg'
import contractorImg from '../../assets/img/pages/articles/4/Contractors execute the highest risks activities for employing companies.jpg'
// import iogpImg from '../../assets/img/pages/articles/4/how-are-contractors-learning-from-these-incidents.jpg'
// import vrImg from '../../assets/img/pages/articles/4/What solutions are available to train contractor workers.jpg'
// import graphImg from '../../assets/img/pages/articles/4/potential lives saved by the revised IOGP LSR graph.jpg'

const NewInnovativeWay = ({location}) => {
  const [pageTitle] = useState('New and Innovative Way to Train Contractor Workers on Fatal HSE Risks.')
  const state = useContext(GlobalStateContext);
  let container = useRef(null);
  const slug = useState(location.pathname.split('/')[2]);
  let endDiv = useRef(null);
  const [endDivPosition, setEndDivPosition] = useState(0);

  const [showImgPrev,setShowImgPrev]=useState(false)
  const [activeImg,setActiveImg]=useState(empty)

  const handeleActiveImg = (img)=> {
    setActiveImg(img)
    setShowImgPrev(true)
  }

  const updatePosition = () => {
    setEndDivPosition(endDiv.current.getBoundingClientRect().top)
  };

  useEffect(() => {
    window.addEventListener("scroll", updatePosition);
    
    return () => window.removeEventListener("scroll", updatePosition);
  }, [endDivPosition]);

  useEffect(() => {
    if (!state.showPageLoading) {
      const cn = container.current;
      setTimeout(() => {
        cn.classList.remove("hide-container");
      }, state.containerAnimationDelay * 1000);
    }
  }, [state.showPageLoading, state.containerAnimationDelay]);

  return (
    <Fragment>
      <ImagePreview img={activeImg} orientation="landscape" onClick={()=>setShowImgPrev(!showImgPrev)} show={showImgPrev}/>
      <Layout>
        <Seo
          title={pageTitle}
          description='The contractor workers run much higher risk of a fatal incident than company workers. This is clear by the safety statistics collected by IOGP.'
          image={mainImg}
          ogType='article'
        />
        <PageBanner title='Articles' useDiv={true}/>
        <section
          className='main-container single-article hide-container'
          ref={container}
        >
          <div className='row less-mar bottom-pad'>
            <SocialMedia 
              pageUrl={location.href} 
              pageTitle={pageTitle}
              endDivPosition={endDivPosition}
            />
            {/* Article */}
            <article className='column article-details'>
              <header>
                <h1 className="main-title small primary-color">
                  <strong>New and Innovative Way to Train Contractor Workers on Fatal HSE Risks.</strong>
                </h1>
                <div className='author-date'>
                  By <strong>Willem Peuscher</strong>
                  <span className='spacer'>|</span>December 12, 2021
                  <span className='spacer'>|</span>5 minute read
                </div>
              </header>
              <div 
                onClick={()=>handeleActiveImg(mainImg)} 
                aria-hidden className='article-main-image'>
                  <StaticImage
                    src='../../assets/img/pages/articles/4/New and innovative way to train contractor workers on fatal HSE risks.jpg'
                    alt='New and innovative way to train contractor workers on fatal HSE risks'
                    title='New and innovative way to train contractor workers on fatal HSE risks'
                    quality="100"
                    objectFit
                    className='gatsby-image'
                  />
              </div>
              <h2 className="main-subtitle">Contractors execute the highest risks activities for employing companies.</h2>
              <p className="justify mar-bottom no-mar-top">If you want to know more about how employing contractor can help contractors to work safely, pls subscribe yourself to the newsletter and you will get the article.</p>
              
              <p className="justify mar-bottom no-mar-top">The contractor workers run much higher risk of a fatal incident than company workers. This is clear by the safety statistics collected by IOGP<Link to="#link-group"><sup id="anchor1">[1]</sup></Link>. These statistics show that some 80%<Link to="#link-group"><sup id="anchor2">[2]</sup></Link> of the fatal incidents affects (sub)contractors. It is well-documented that employing companies can reduce the number of contractor fatalities significantly within a few year  period by building on safe systems of work and impacting the safety culture by effectively introducing the Life-Saving Rules<Link to="#link-group"><sup id="anchor3">[3]</sup></Link>.</p>
              <div 
                onClick={()=>handeleActiveImg(contractorImg)} 
                aria-hidden className='article-main-image'>
                  <StaticImage
                    src='../../assets/img/pages/articles/4/Contractors execute the highest risks activities for employing companies.jpg'
                    alt='Contractors execute the highest risks activities for employing companies'
                    title='Contractors execute the highest risks activities for employing companies'
                    quality="100"
                    objectFit
                    className='gatsby-image'
                  />
              </div>

              <h2 className="main-subtitle">How can employing companies help contractors to work safely?</h2>
              <p className="justify no-mar-top">If you want to know more about how employing contractor can help contractors to work safely, please subscribe yourself to the newsletter and you will get the article.</p>
              <div style={{'marginTop':'20px', 'zIndex':2}}>
                <Link to="/lp/download-article/" state={{ slug: slug[0] }} className="button-link">Subscribe and Download</Link>
              </div>
              
              {/* <p className="justify no-mar-top">Contractor workers often move from one employing company to another and every time, they need to learn the employing company’s safety rules. By adopting 9 IOGP Life-Saving Rules<Link to="#link-group"><sup id="anchor4">[4]</sup></Link> as industry standard, employing companies can help contractors and their workers to prepare better and work safely. The Life-Saving Rules cover the majority of life-threatening operational risks. With these rules, the contractor workers are better able to make interventions and speak up, as the rules are the same everywhere. It is also found that these workers become more receptive to other risks as overall safety performance improves<Link to="#link-group"><sup id="anchor2">[2]</sup></Link>.</p> */}

              {/* <h2 className="main-subtitle">How are contractors learning from these incidents?</h2> */}
              {/* <p className="justify no-mar-top">If a company experiences a fatality, most probably, compliance with one of the 9 IOGP Life-Saving rules could have prevented this sad outcome, as these rules cover about 70% of the fatal activities in the oil and gas, logistics, and construction industry<Link to="#link-group"><sup id="anchor2">[2]</sup></Link>.</p>

              <p className="justify mar-bottom">By adopting the Life-Saving Rules and embed them in the hearts and minds of people, incidents can be prevented. If this is done consistently, it becomes part of the safety culture. <strong>Learning the 9 IOGP Life-Saving Rules are in fact the simplest way of ‘learning from incidents’.</strong> The rules are simple, executable, visual and also auditable.</p> */}

              {/* <div 
                onClick={()=>handeleActiveImg(iogpImg)} 
                aria-hidden className='article-main-image'>
                  <StaticImage
                    src='../../assets/img/pages/articles/4/how-are-contractors-learning-from-these-incidents.jpg'
                    alt='How are contractors learning from these incidents'
                    title='How are contractors learning from these incidents'
                    quality="100"
                    objectFit
                    className='gatsby-image'
                  />
              </div> */}
              {/* <p className="justify">Of course, the rules are only effective when the workers understand these fully and are able to comply with them. Timely and effective training is therefore important, as well as the ability of workers to recognise the risks and feel empowered to make interventions.</p> */}

              {/* <h2 className="main-subtitle">What solutions are available to train contractor workers?</h2>
              <p className="justify no-mar-top">Informing the workers about the risks is in practice a challenge, and various ways were developed in the past: pre-job discussions, site inductions, Permit-to-Work system, alerts, competence profiles, etc</p>
              <p className="justify mar-bottom">Nowadays, there is a new and innovative training method, which is refreshing, effective,  easy-to-do, standardised, and impacting the hearts and minds of workers: <strong>Use Virtual Reality games, playing on a VR headset.</strong> Dependent on the job at hand, it can take 5 to 15 minutes to play the VR game. At the end of the game, the worker has seen the risks in his job and the way how to do work safely. Also, the workers learn to recognise the hazards in the field and make interventions. Workers do not need to go to a training centre, modern VR headsets can work anywhere. The training can come to the worker. Frequent quick training before a job starts is now feasible anywhere.<Link to="#link-group"><sup id="anchor5">[5]</sup></Link></p> */}

              {/* <div 
                onClick={()=>handeleActiveImg(vrImg)} 
                aria-hidden className='article-main-image' style={{height:'auto'}}>
                  <img className="normal-image" src={vrImg} alt="What solutions are available to train contractor workers" title="What solutions are available to train contractor workers"/> */}
                  {/* <StaticImage
                    src='../../assets/img/pages/articles/4/What solutions are available to train contractor workers.jpg'
                    alt='What solutions are available to train contractor workers'
                    title='What solutions are available to train contractor workers'
                    quality="100"
                    objectFit
                    className='gatsby-image'
                  /> */}
              {/* </div> */}

              {/* <h2 className="main-subtitle">Example of VR headset as training means:</h2>
              <p className="justify no-mar-top">Recently, a safety alert was issued by a company showing a fatal incident while a truck was moving backwards and hitting a person who was standing behind. IOGP’s data shows that about 1/3 of IOGP’s fatal incidents are related to “standing in the Line of Fire”. This incident has also happened many, many times before.</p>

              <p className="justify">In the VR game, this particular situation of the risks of ‘standing in the line of fire’ of a reversing truck is shown. In the VR game, the trainee is triggered to recognize the risk, make interventions and advise the safe way of operations. The VR game is “immersive“, and  it uses 3D vision, directional sound, hand vibrations, visual clues, and motivational elements to deliver the learning as effectively as possible. There are even language-free games; all staff can participate.</p>

              <p className="justify mar-bottom">If the victim in the mentioned incident would have been instructed with suitable VR games once, it is very likely that he or his peers would have prevented the life-threating situation when it occurred. In the VR training, they would have seen the hazard in a very realistic way with the immersive 3D video game. They would have recognized the hazard and made an intervention as they learned in the game.</p> */}

              {/* <div 
                onClick={()=>handeleActiveImg(graphImg)} 
                aria-hidden className='article-main-image' style={{height:'auto'}}>
                  <img className="normal-image" src={graphImg} alt="Potential lives saved by the revised IOGP LSR graph" /> */}
                  {/* <StaticImage
                    src='../../assets/img/pages/articles/4/potential lives saved by the revised IOGP LSR graph.jpg'
                    alt='Potential lives saved by the revised IOGP LSR graph'
                    title='Potential lives saved by the revised IOGP LSR graph'
                    quality="100"
                    // objectFit
                    className='gatsby-image constrained'
                  /> */}
              {/* </div> */}
              

              {/* <h2 className="main-subtitle mar-top">Interested in VR games training?</h2>
              <p className="justify no-mar-top mar-bottom">VIRSAT LLC designed safety VR learning games for employees, contractors, craftsman, using the Life-Saving Rules as basis. With these games, the workforce can practice their skills dealing with the most hazardous jobs in their respective industries. The games build on the experience of the workers and help them to take the right actions. In a friendly way, their competence is strengthened and confirmed. Dependent on the level of comprehension required, the games are designed as playful or as more serious in nature. The games are especially useful for mature learners.</p> */}

              <div>
                <ul className="links-anchors" id="link-group">
                  <li>
                    <a href="#anchor1">[1]</a> IOGP is the International Association of Oil and Gas producers
                  </li>
                  <li>
                    <a href="#anchor2">[2]</a> IOGP report 459 Life-Saving Rules&nbsp;
                    <a href="https://www.iogp.org/bookstore/product/life-saving-rules/">link</a>
                  </li>
                  <li>
                    <a href="#anchor3">[3]</a>&nbsp;
                    <a href="https://onepetro.org/SPEHSE/proceedings/12HSE/All-12HSE/SPE-157465-MS/158675">
                    A Big Oil Company's Approach to Significantly Reduce Fatal Incidents
                    </a>
                  </li>
                  {/* <li>
                  <a href="#anchor4">[4]</a>&nbsp;
                    <a href="https://www.iogp.org/life-savingrules/#1533821447568-5d79c065-a992">
                    https://www.iogp.org/life-savingrules/#1533821447568-5d79c065-a992
                    </a>
                  </li>
                  <li>
                    <a href="#anchor5">[5] </a>Except for explosion proof areas.
                  </li> */}
                </ul>
              </div>

              <div className="footer-note primary-bg">
                <div>
                  <p className='no-mar'>
                  If you want to learn more, see the brochure - <a target="blank" href="https://secure.virsat.com/pdf/VIRSAT-Life-Saving-Rules-Aware.pdf">Life-Saving Rules Aware-Knowledge</a>, or write to <a href="mailto:info@virsat.com">info@virsat.com</a>. 
                  </p>
                </div>
                <div>
                  <a target="blank" href="https://secure.virsat.com/pdf/VIRSAT-Life-Saving-Rules-Aware.pdf"><img src={lsrImg} alt='VIRSAT Life Saving Rules Aware'/></a>
                </div>
              </div>

              <div style={{height:'1px','width':'1px'}} ref={endDiv}/>
            </article>
            {/* End: Article */}

            {/* Sidebar */}
            <Sidebar slug={slug} endDivPosition={endDivPosition}/>
          </div>
        </section>
      </Layout>
    </Fragment>
  )
}

export default NewInnovativeWay
